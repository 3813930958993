<template>
  <div class="full-height">
    <Navbar :logged="true" :app="false"/>

    <b-row class="justify-content-md-center" style="width:100%">
      <b-col sm="5">
        <b-card
          title="Contexte"
          img-src="@/assets/context.jpg"
          img-alt="Image"
          img-top
          class="mt-4"
        >
          <b-card-text>
            À l’ADEME - l’Agence de la transition écologique -, nous sommes résolument engagés dans la lutte 
            contre le réchauffement climatique et la dégradation des ressources.<br>
            Sur tous les fronts, nous mobilisons les citoyens, les acteurs économiques et les territoires, 
            leur donnons les moyens de progresser vers une société économe en ressources, plus sobre en carbone, 
            plus juste et harmonieuse.<br>
            Dans tous les domaines - énergie, air, économie circulaire, gaspillage alimentaire, déchets, sols… - 
            nous conseillons, facilitons et aidons au financement de nombreux projets, de la recherche jusqu’au partage des 
            solutions.
            <br>
            À tous les niveaux, nous mettons nos capacités d’expertise et de prospective au service des politiques publiques.
            <br><br>
            Le service industrie (SI) a sollicité la société Cross Data en 2019 pour lui fournir une solution lui permettant 
            de regrouper les informations dont il dispose sur les entreprises françaises industrielles, potentiellement 
            émettrices de polluants ou consommant une quantité importante d’énergie.
            <br>
            La société Cross Data, a ainsi développé en 2019 un outil de plateforme dynamique permettant de placer sur une 
            carte, les différents sites industriels à partir de leur N°SIRET. Cette fonctionnalité est couplée à une 
            connexion avec certaines données en open data de l’INSEE. Ainsi, il est possible de connecter des données « métiers 
            ADEME » avec des données libres en utilisant la clé N°SIRET.

          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="3">
        <b-card
          title="Objectif"
          img-src="@/assets/obj.jpg"
          img-alt="Image"
          img-top
          class="mt-4"
        >
          <b-card-text>
            L’objectif principal de l'outil, pour l’ADEME, est d’identifier les entreprises fortement 
            consommatrices d’énergie et/ou émettrices de GES et donc à fort potentiel d’économie 
            d’énergie/GES, via une cartographie reposant sur les données en possession de l’Agence 
            issues de diverses sources (audit énergie par exemple), croisées avec des données ouvertes 
            provenant des référentiels externes (INSEE par exemple).
            <br><br>
            L’outil contribue à faciliter la visualisation, l’analyse et la manipulation des données 
            en fonction du territoire considéré, tout en les enrichissant. Il a vocation à être manipulé 
            par les directions régionales de l’Agence à des fins de prospection, dans l’optique d’une 
            « approche client » pour les grands comptes industriels.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center" style="width:100%">
      <b-col sm="3">
        <b-card
          title="Préfiltres"
          img-src="@/assets/filter.jpg"
          img-alt="Image"
          img-top
          class="mt-4 mb-4"
        >
          <b-card-text>
            Les établissements présents dans l'outil sont actifs et leur diffusion est autorisée.
            Ils ont une activité correspondant à un code NAF sélectionné par l'ADEME. Ces activités 
            correspondent aux familles suivantes : <br>
            - Industries extractives,<br>
            - Industrie manufacturière,<br>
            - Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné,<br>
            - Production et distribution d'eau, assainissement, gestion des déchets et dépollution,<br>
            - Transports et entreposage,<br>
            - Information et communication.

          </b-card-text>
        </b-card>
      </b-col>
      <b-col sm="5">
        <b-card
          title="Source de données"
          img-src="@/assets/data.jpg"
          img-alt="Image"
          img-top
          class="mt-4 mb-4"
        >
          <b-card-text>
            <b>Données d'audit, de consommation et d'émission</b><br>
            Une partie des données utilisées provient de l’ADEME. Ces données concernent les consommations 
            d’énergie et les statuts des établissements (« Audité », « Certifié iso 50001 », « Pas de documents ») 
            provenant de la plateforme de recueil des audits énergétiques.<br>
            Les données des émissions de polluants proviennent de la base Géorisques et sont couplées aux données 
            ETS datant de 2017.
            <br><br>
            <b>Données géologiques</b><br>
            L’ADEME a également mis à disposition des données provenant du BRGM (Bureau de Recherches 
            Géologiques et Minières). Ces données représentent les zones de stockage de CO2 en France 
            (niveaux trias et dogger) et ont été complétées avec les données de la base de données européenne des 
            zones de stockage de CO2 (projet CO2StoP). 
            <br><br>
            <b>Données d'aléas climatiques</b><br>
            Les données d'aléas climatiques proviennent des sources DRIAS, GéoLittoral et Géorisques. 
            <br><br>
            <b>Données administratives</b><br>
            Enfin, les données administratives des établissements proviennent de la base de données Sirene, mise à disposition 
            par l’INSEE. Dans cette version, les données de la base Sirene proviennent de la base de Cross Data, 
            dans laquelle elles sont nettoyées et mises à jour régulièrement. 
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "About",
  components: { Navbar },
  
};
</script>

<style scoped>

</style>
